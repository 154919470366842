.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* width: 100%; */
  height: auto;
  background-color: transparent;
  margin-bottom: 90px;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 20px;
  width: 80%;
  max-width: 800px;
  align-items: center;
}

.form-item {
  display: flex;
  align-items: center;
}

.form-item p {
  margin: 0;
}

.selectElevation {
  z-index: 2;
}

.time-input-style {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-input-style1 .MuiPickersDay-root {
  z-index: 1000;
}

.portal-container {
  position: fixed;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
}

.input-style {
  margin-top: 20px;
}

h2,
h3 {
  margin: 5px 0;
}