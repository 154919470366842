:root {
    --header-height: 70px;
    --logo-height: 35px;
    --title-color: white;
    --title-font-size: 1.5em;
}

.headerContainer {
    width: 100%;
    background-color: black;
    height: var(--header-height);
    position: fixed;
    top: 0;
    z-index: 15;
    left: 0;
}

.image-logo {
    height:  var(--logo-height);
    left:calc(var(--logo-height) / 2);
    top: calc(var(--logo-height) / 2);
    position: relative;
}
.title-style{
    color: var(--title-color);
    font-size: var(--title-font-size);
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    word-break: break-word;
}