.image-background {
    width: 100%;
    height: 100%;
    left: 0;
    top:0;
    right: 0;
    position: fixed;
    z-index: -1;
    object-fit: cover;
    opacity: 0.2;
}

.content-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100% - 50px);
    width: 95vw;
    align-self: center;
    margin-left: auto;
    margin-right: auto;

}

.mobile-wrapper{
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 50px);
}

.reservation-history-container {
    margin-bottom: 20px;
}

.slot-map-container {
    margin-top: 20px;
}

.alert{
    justify-content: center;
    align-items: center;
    position: fixed;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 10px;
}


.reservation-page-container {
    display: flex;
    justify-content: space-around;
    /* Adjust alignment as needed */
    align-items: flex-start;
    /* Align items to the top */
    width: 100%;
    height: auto;
    margin-top: 90px;
    /* Adjust top margin as needed */
}

.reservation-form-container,
.summary-container {
    width: calc(50% - 20px);
    /* Adjust width and margin as needed */
    margin: 0 10px;
    /* Adjust margin as needed */
}