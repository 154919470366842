@import url('https://fonts.google.com/specimen/Lato?stroke=Sans+Serif');

html,body, div#root{
    height: 100%;
}

body {
    margin: 0;   
    font-family: 'Lato', sans-serif;
}
