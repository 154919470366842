.input-style {
    padding: 0 15px;
    text-align: center;
    justify-content: center;
    margin-top: 20px;
    min-width: 80px;
    /* padding-bottom: 10px; */
}

.selectElevation {
    z-index: 10;
}