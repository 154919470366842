.title {
    margin-right: 10px;

}

.text-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
 
}
