.foundation-footer {
  width: 100%;
  background-color: #212121;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  position: relative;
  bottom: 0; 
  left: 0;
  z-index: 1;
}